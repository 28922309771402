
import "./radio-v1"
import "./checkbox-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class SurveyImageOptionV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    const inputElement = this.element.querySelector('.elSurveyImageOptionBase__Input').firstChild

    const fireClickOnInput = () => {
      inputElement.click()
    }

    inputElement.addEventListener('click', fireClickOnInput)
    this.element.addEventListener('click', fireClickOnInput)
  }



}

window["SurveyImageOptionV1"] = SurveyImageOptionV1

